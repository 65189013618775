import { alchemyProvider } from '@wagmi/core/providers/alchemy'
import { PortisConnector } from '@web3-react/portis-connector'
import { configureChains, createConfig } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { SafeConnector } from 'wagmi/connectors/safe'
import { WalletConnectConnector as WC } from 'wagmi/connectors/walletConnect'
// import { alchemyProvider } from 'wagmi/providers/alchemy'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'

import { INFURA_KEY, PORTIS_ID, WALLET_CONNECT_PROJECT_ID } from '../constants/config'
import { ChainId, NETWORK_CONFIGS, mainnet, sepolia } from '../utils/networkConfig'

// import {  mainnet, sepolia } from 'wagmi/chains'

console.log('sepolia', sepolia)

// const myProvider = jsonRpcProvider({
//   rpc: (chain) => ({
//     http: `https://eth-sepolia.g.alchemy.com/v2/uxQRVc2wghCC2mhpukmFVd_vfCYSfdjh`,
//   }),
// })

// const pp = publicProvider()

// console.log('pp', pp)

// console.log('myProvider', myProvider)

// console.log('WALLET_CONNECT_PROJECT_ID', WALLET_CONNECT_PROJECT_ID)

const { chains, publicClient } = configureChains(
  [mainnet, sepolia],

  // [
  //   jsonRpcProvider({
  //     rpc: (chain) => ({
  //       http: `https://eth-sepolia.g.alchemy.com/v2/uxQRVc2wghCC2mhpukmFVd_vfCYSfdjh`,
  //     }),
  //   }),
  //   publicProvider(),
  // ],
  [alchemyProvider({ apiKey: 'uxQRVc2wghCC2mhpukmFVd_vfCYSfdjh' }), publicProvider()],
  // [infuraProvider({ apiKey: INFURA_KEY }), publicProvider()],
)

export { chains }

const metamaskConnector = new MetaMaskConnector({ chains })
export const injected = new InjectedConnector({ chains })
const coinbaseWalletConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'gnosis-auction.eth',
    jsonRpcUrl: `${mainnet.rpcUrls.public.http}`,
  },
})
export const walletConnectConnector = new WC({
  chains,
  options: {
    projectId: WALLET_CONNECT_PROJECT_ID,
    metadata: {
      name: 'gnosis-auction',
      description: 'Decentralised token price discovery platform',
      url: 'gnosis-auction.eth',
      icons: [],
    },
  },
})

const safeConnector = new SafeConnector({
  chains,
  options: {
    allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
    debug: true,
  },
})

export const wagmiClient = createConfig({
  autoConnect: true,
  connectors: [
    metamaskConnector,
    injected,
    coinbaseWalletConnector,
    walletConnectConnector,
    safeConnector,
  ],
  publicClient,
})

console.log('wagmiClient', wagmiClient)

const urls: string[] = []

// TOOD Try to use reduce to improve types
const rpcs: any = {}

const chainIds = Object.keys(NETWORK_CONFIGS).map(Number)
chainIds.forEach((chainId: ChainId) => {
  if (NETWORK_CONFIGS[chainId].rpcUrls.default) {
    urls[chainId] = `${NETWORK_CONFIGS[chainId].rpcUrls.default.http}`
    rpcs[chainId] = NETWORK_CONFIGS[chainId].rpcUrls.default.http
  }
})

// console.log('chainIds', chainIds)
console.log('PORTIS_ID', PORTIS_ID)
// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID,
  networks: [1],
})
