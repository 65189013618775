import { Chain, mainnet, sepolia } from 'wagmi/chains'

export { mainnet, sepolia }
export type { Chain }

export enum ChainId {
  MAINNET = 1,
  SEPOLIA = 11155111,
}

export const NETWORK_CONFIGS: {
  [chainId in ChainId]: Chain
} = {
  [mainnet.id]: mainnet,
  [sepolia.id]: sepolia,
}
