import { BigNumber, ethers } from 'ethers'
import { useEffect, useMemo, useState } from 'react'

import { ContractCallContext, ContractCallResults, Multicall } from 'ethereum-multicall'
// import { Contract } from 'ethers-multicall'

import AltarABI from '../constants/abis/altar/altar.json'
import ERC20ABI from '../constants/abis/erc20/erc20.json'
import SablierABI from '../constants/abis/sablier/sablier.json'
import { ALTAR_NETWORK, FLX_NETWORK, KITE_NETWORK, SABLIER_NETWORK } from '../utils'
import { getLogger } from '../utils/logger'

const logger = getLogger('useAuctionDetails')

export interface AltarStreamData {
  deposit: ethers.BigNumber
  ratePerSecond: ethers.BigNumber
  sender: string
  startTime: Date
  stopTime: Date
  streamBalanceOfAltar: ethers.BigNumber
  streamBalanceOfTreasury: ethers.BigNumber
  remainingBalance: ethers.BigNumber
  token: string
}

export interface AltarInfoDetails {
  streamId: ethers.BigNumber
  streamData: Maybe<AltarStreamData>
  nextPokeTime: Date
  pokeCooldown: ethers.BigNumber
  altarKiteBalance: ethers.BigNumber
  altarFlxBalance: ethers.BigNumber
  auctions: ethers.BigNumber[]
}

const hexToDecimal = (hex: string) => ethers.BigNumber.from(hex).toNumber()

const dateFormatter = (bN: ethers.BigNumber) => {
  return new Date(bN.toNumber() * 1000)
}

export const useAltarData = () => {
  const [altarInfo, setAltarInfo] = useState<Maybe<AltarInfoDetails>>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const main = useMemo(() => {
    const provider = new ethers.providers.JsonRpcProvider(
      'https://eth-sepolia.g.alchemy.com/v2/uxQRVc2wghCC2mhpukmFVd_vfCYSfdjh',
    )

    const multicall = new Multicall({ ethersProvider: provider, tryAggregate: true })
    const sepoliaId = 11155111

    return async (initial: boolean) => {
      if (initial) {
        setLoading(true)
      }

      try {
        const baseContractCallContext: ContractCallContext[] = [
          {
            reference: 'altarContract',
            contractAddress: ALTAR_NETWORK[sepoliaId],
            abi: AltarABI,
            calls: [
              { reference: 'streamId', methodName: 'streamId', methodParameters: [] },
              { reference: 'nextPokeTime', methodName: 'nextPokeTime', methodParameters: [] },
              { reference: 'pokeCooldown', methodName: 'pokeCooldown', methodParameters: [] },
              { reference: 'auctions', methodName: 'getAuctions', methodParameters: [] },
            ],
          },
          {
            reference: 'kiteContract',
            contractAddress: KITE_NETWORK[sepoliaId],
            abi: ERC20ABI,
            calls: [
              {
                reference: 'balanceOf',
                methodName: 'balanceOf',
                methodParameters: [ALTAR_NETWORK[sepoliaId]],
              },
            ],
          },
          {
            reference: 'flxContract',
            contractAddress: FLX_NETWORK[sepoliaId],
            abi: ERC20ABI,
            calls: [
              {
                reference: 'balanceOf',
                methodName: 'balanceOf',
                methodParameters: [ALTAR_NETWORK[sepoliaId]],
              },
            ],
          },
        ]
        const baseContractCallResults: ContractCallResults = await multicall.call(
          baseContractCallContext,
        )
        const baseResults = baseContractCallResults.results
        // Contract Call Contexts
        const altarCallsContext = baseResults.altarContract.callsReturnContext
        const flxCallsContext = baseResults.flxContract.callsReturnContext
        const kiteCallsContext = baseResults.kiteContract.callsReturnContext

        // Stream ID
        const streamIdResult: BigNumber = BigNumber.from(altarCallsContext[0].returnValues[0])
        const streamId = streamIdResult.toNumber()
        // Next Poke Time
        const nextPokeTimeResult: BigNumber = BigNumber.from(altarCallsContext[1].returnValues[0])
        const nextPokeTime = nextPokeTimeResult.toNumber()
        // Poke Cooldown
        const pokeCooldownResult: BigNumber = BigNumber.from(altarCallsContext[2].returnValues[0])
        const pokeCooldown = pokeCooldownResult.toNumber()
        // Auctions
        const auctionsResult = altarCallsContext[3].returnValues
        const auctions = auctionsResult.map((auction: BigNumber) => BigNumber.from(auction))

        const altarFlxBalanceResult: BigNumber = BigNumber.from(flxCallsContext[0].returnValues[0])
        // const altarFlxBalance = altarFlxBalanceResult.toNumber()

        const altarKiteBalanceResult: BigNumber = BigNumber.from(
          kiteCallsContext[0].returnValues[0],
        )
        // const altarKiteBalance = altarKiteBalanceResult.toNumber()

        // console.log('streamId', streamId)
        // console.log('nextPokeTime', nextPokeTime)
        // console.log('pokeCooldown', pokeCooldown)
        // console.log('auctionsResult', auctionsResult)
        // console.log('auctions', auctions)
        // console.log('altarFlxBalance', altarFlxBalance)
        // console.log('altarKiteBalance', altarKiteBalance)

        try {
          // Stream Info
          const streamContract = new ethers.Contract(
            SABLIER_NETWORK[sepoliaId],
            SablierABI,
            provider,
          )
          const streamInfo = await streamContract.getStream(streamId)
          console.log('streamInfo', streamInfo)

          const streamBalanceContractCallContext: ContractCallContext[] = [
            {
              reference: 'sablierContract',
              contractAddress: SABLIER_NETWORK[sepoliaId],
              abi: SablierABI,
              calls: [
                {
                  reference: 'streamBalanceOfAltar',
                  methodName: 'balanceOf',
                  methodParameters: [streamId, ALTAR_NETWORK[sepoliaId]], // Altar address
                },
                {
                  reference: 'streamBalanceOfTreasury',
                  methodName: 'balanceOf',
                  methodParameters: [streamId, streamInfo.sender], // Treasury address
                },
              ],
            },
          ]

          const streamBalanceContractCallResults: ContractCallResults = await multicall.call(
            streamBalanceContractCallContext,
          )
          const streamBalanceResults = streamBalanceContractCallResults.results
          const sablierCallsContext = streamBalanceResults.sablierContract.callsReturnContext
          // console.log('sablierCallsContext', sablierCallsContext)
          //  const baseResults = baseContractCallResults.results
          // Contract Call Contexts
          // const altarCallsContext = baseResults.altarContract.callsReturnContext

          const altarStreamBalanceResult: BigNumber = BigNumber.from(
            sablierCallsContext[0].returnValues[0],
          )
          // const altarStreamBalance = altarStreamBalanceResult.toNumber()
          // console.log("altarStreamBalance", altarStreamBalance)

          const treasuryStreamBalanceResult: BigNumber = BigNumber.from(
            sablierCallsContext[1].returnValues[0],
          )
          // const treasuryStreamBalance = treasuryStreamBalanceResult.toNumber()
          // console.log('treasuryStreamBalance', treasuryStreamBalance)

          // console.log('streamId', streamId)

          setAltarInfo({
            streamId: streamIdResult,
            altarKiteBalance: altarKiteBalanceResult,
            altarFlxBalance: altarFlxBalanceResult,
            nextPokeTime: dateFormatter(nextPokeTimeResult),
            pokeCooldown: pokeCooldownResult,
            auctions: auctionsResult,
            streamData: {
              deposit: streamInfo.deposit,
              ratePerSecond: streamInfo.ratePerSecond,
              sender: streamInfo.sender,
              startTime: dateFormatter(streamInfo.startTime),
              stopTime: dateFormatter(streamInfo.stopTime),
              remainingBalance: streamInfo.remainingBalance,
              token: streamInfo.token,
              streamBalanceOfAltar: altarStreamBalanceResult,
              streamBalanceOfTreasury: treasuryStreamBalanceResult,
            },
          })
        } catch (err) {
          console.log(err)

          setAltarInfo({
            streamId: streamIdResult,
            altarKiteBalance: altarKiteBalanceResult,
            altarFlxBalance: altarFlxBalanceResult,
            nextPokeTime: dateFormatter(nextPokeTimeResult),
            pokeCooldown: pokeCooldownResult,
            auctions: auctionsResult,
            streamData: null,
          })
        }
        if (initial) {
          setLoading(false)
        }
      } catch (err) {
        console.log(err)
        if (initial) {
          setLoading(false)
          setAltarInfo(null)
        }
        logger.error('Error getting altar details', err)
      }
    }
  }, [])

  useEffect(() => {
    main(true)
    // setInterval(() => {
    //   main(false)
    // }, 10000)
  }, [main])

  const refetch = () => main(false)

  return { loading, altarInfo, refetch }
}
