import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// import { useActiveWeb3React } from '../../../hooks'
import { useClaimCallback } from '../../../hooks/useClaimCallback'
import ConfirmationModal from '../../modals/ConfirmationModal'
// import SwapModalFooter from '../../modals/common/PlaceOrderModalFooter'

const Container = styled.div`
  border: 2px solid black;
  padding: 3px;
  border-radius: 16px;
`

const DisabledButton = styled.div`
  border: 2px solid black;
  padding: 0.5em 2em;
  border-radius: 12px;
  font-size: 19px;
  background-color: #c8c8c8;
  cursor: not-allowed;
`

const ActiveButton = styled.div`
  border: 2px solid black;
  padding: 0.5em 2em;
  border-radius: 12px;
  font-size: 19px;
  background-color: #fda08b;
  transition: 200ms;
  cursor: pointer;
  color: black;
  &:hover {
    background-color: #ff6543;
  }
`

const ClaimButton = () => {
  //      <ActiveButton>Poke Now!</ActiveButton>

  //   const { account, chainId, library } = useActiveWeb3React()

  const [pendingConfirmation, setPendingConfirmation] = useState<boolean>(true) // waiting for user confirmation

  const claim = useClaimCallback()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirmed
  const [txHash, setTxHash] = useState<string>('')

  useEffect(() => {
    console.log(attemptingTxn)
  }, [attemptingTxn])

  //   if (finished)
  //     return (
  //       <Container>
  //         <DisabledButton>No more $KITE to sacrifice!</DisabledButton>
  //       </Container>
  //     )

  //   if (diff > 0)
  //     return (
  //       <Container>
  //         <DisabledButton>Can Poke in {timeFormatter(diff)}</DisabledButton>
  //       </Container>
  //     )

  //   if (!account) {
  //     return (
  //       <Container>
  //         <DisabledButton>Connect To Poke</DisabledButton>
  //       </Container>
  //     )
  //   }

  //   if (chainId !== 11155111) {
  //     return (
  //       <Container>
  //         <DisabledButton>Switch to Sepolia to Claim</DisabledButton>
  //       </Container>
  //     )
  //   }

  const resetModal = () => {
    setPendingConfirmation(true)
    setAttemptingTxn(false)
  }

  return (
    <>
      <Container>
        <ActiveButton
          onClick={() => {
            setShowConfirm(true)
            setAttemptingTxn(true)
            claim()
              .then((hash) => {
                setTxHash(hash)
                setPendingConfirmation(false)
              })
              .catch((err) => {
                resetModal()
                setShowConfirm(false)
              })
          }}
        >
          Claim Test FLX
        </ActiveButton>
      </Container>
      <ConfirmationModal
        attemptingTxn={attemptingTxn}
        content={<div>Twt</div>}
        hash={txHash}
        isOpen={showConfirm}
        onDismiss={() => {
          resetModal()
          setShowConfirm(false)
        }}
        pendingConfirmation={pendingConfirmation}
        pendingText="Claiming Test FLX"
        title="Claiming Test FLX"
        width={504}
      />
    </>
  )
}

export default ClaimButton
