import { ethers } from 'ethers'

import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { Provider, Web3Provider } from '@ethersproject/providers'
import { parseBytes32String } from '@ethersproject/strings'
import { JSBI, Percent, Token, TokenAmount, WETH } from '@josojo/honeyswap-sdk'
// import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import IUniswapV2Pair from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { PublicClient } from 'viem'

import { getLogger } from './logger'
import { ChainId, NETWORK_CONFIGS } from './networkConfig'
import easyAuctionABI from '../constants/abis/easyAuction/easyAuction.json'
import ERC20_ABI from '../constants/abis/erc20.json'
import ERC20_BYTES32_ABI from '../constants/abis/erc20_bytes32.json'

export { ChainId }

const logger = getLogger('utils/index')

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export const ALTAR_NETWORK = {
  [ChainId.SEPOLIA]: '0x036d64c274b43C86430AcF92Ee8fA73bAd78F4a5',
}

export const FAUCET_NETWORK = {
  [ChainId.SEPOLIA]: '0xD96e4B1183b6fA03139aE0c41f6bc7C8C5fBb371',
}

export const SABLIER_NETWORK = {
  [ChainId.SEPOLIA]: '0x67b61b6098c60a128c206e15275149903f7A18a1',
}

export const FLX_NETWORK = {
  [ChainId.SEPOLIA]: '0xEB2473255A6074258171cD9CeCcC4D252E3E0415',
}

export const KITE_NETWORK = {
  [ChainId.SEPOLIA]: '0x19337DE3cbD16FBcEA5eD6C52fbCBE958Ae184a5',
}

export const EASY_AUCTION_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xf0265fe276C395e7C6969fc149844df571f2E7F0',
  [ChainId.SEPOLIA]: '0xf0265fe276C395e7C6969fc149844df571f2E7F0',
}

// [ChainId.MAINNET]: '0x10D15DEA67f7C95e2F9Fe4eCC245a8862b9B5B96'
export const DEPOSIT_AND_PLACE_ORDER: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x0f3392Bea17d25F87010D736b00f9c4fbe9a543e',
  [ChainId.SEPOLIA]: '0x0f3392Bea17d25F87010D736b00f9c4fbe9a543e',
}

// [ChainId.MAINNET]: '0x0F4648d997e486cE06577d6Ee2FecBcA84b834F4',
export const ALLOW_LIST_OFF_CHAIN_MANAGED: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xE0AD16EB7Ea467C694E6cFdd5E7D61FE850e8B53',
  [ChainId.SEPOLIA]: '0xE0AD16EB7Ea467C694E6cFdd5E7D61FE850e8B53',
}

const getExplorerPrefix = (chainId: ChainId) => {
  return (
    NETWORK_CONFIGS[chainId].blockExplorers?.default.url ||
    `https://${NETWORK_CONFIGS[chainId].blockExplorers?.default.url || ''}etherscan.io`
  )
}

export function getExplorerLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'address',
): string {
  const prefix = getExplorerPrefix(chainId)

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: TokenAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is optional
export function getContract(address: string, ABI: any, provider: Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, provider as Provider)
}

// account is optional
export function getEasyAuctionContract(chainId: ChainId, library: Provider) {
  return getContract(EASY_AUCTION_NETWORKS[chainId], easyAuctionABI, library)
}

export function getEasyAuctionAddress(chainId: ChainId) {
  return EASY_AUCTION_NETWORKS[chainId]
}

// account is optional
export function getExchangeContract(pairAddress: string, library: Provider) {
  return getContract(pairAddress, IUniswapV2Pair.abi, library)
}

// get token info and fall back to unknown if not available, except for the
// decimals which falls back to null
export async function getTokenInfoWithFallback(
  tokenAddress: string,
  library: Web3Provider,
): Promise<{ name: string; symbol: string; decimals: null | number }> {
  if (!isAddress(tokenAddress)) {
    throw Error(`Invalid 'tokenAddress' parameter '${tokenAddress}'.`)
  }

  const token = getContract(tokenAddress, ERC20_ABI, library)

  const namePromise: Promise<string> = token.name().catch(() =>
    getContract(tokenAddress, ERC20_BYTES32_ABI, library)
      .name()
      .then(parseBytes32String)
      .catch((e: Error) => {
        logger.debug('Failed to get name for token address', e, tokenAddress)
        return 'Unknown'
      }),
  )

  const symbolPromise: Promise<string> = token.symbol().catch(() => {
    const contractBytes32 = getContract(tokenAddress, ERC20_BYTES32_ABI, library)
    return contractBytes32
      .symbol()
      .then(parseBytes32String)
      .catch((e: Error) => {
        logger.debug('Failed to get symbol for token address', e, tokenAddress)
        return 'UNKNOWN'
      })
  })
  const decimalsPromise: Promise<Maybe<number>> = token.decimals().catch((e: Error) => {
    logger.debug('Failed to get decimals for token address', e, tokenAddress)
    return null
  })

  const [name, symbol, decimals]: [string, string, Maybe<number>] = (await Promise.all([
    namePromise,
    symbolPromise,
    decimalsPromise,
  ])) as [string, string, Maybe<number>]
  return { name, symbol, decimals }
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

// Always return a non-undefined token display
export function getTokenDisplay(token: Token, chainId: ChainId): string {
  if (isTokenXDAI(token.address, chainId)) return `XDAI`
  if (isTokenWETH(token.address, chainId)) return `ETH`
  if (isTokenWMATIC(token.address, chainId)) return `MATIC`
  return (
    token?.symbol?.slice(0, 7) || token?.name?.slice(0, 7) || token?.address.slice(0, 7) || '🤔'
  )
}

// Always return a non-undefined token display
export function getFullTokenDisplay(token: Token, chainId: ChainId): string {
  if (isTokenXDAI(token.address, chainId)) return `XDAI`
  if (isTokenWETH(token.address, chainId)) return `ETH`
  if (isTokenWMATIC(token.address, chainId)) return `MATIC`
  return token?.symbol || token?.name || token?.address || '🤔'
}

export function isTokenXDAI(tokenAddress?: string, chainId?: ChainId): boolean {
  return false
  // return !!tokenAddress && !!chainId && tokenAddress == WETH[chainId]?.address && chainId === 100
}

export function isTokenWETH(tokenAddress?: string, chainId?: ChainId): boolean {
  return (
    !!tokenAddress &&
    !!chainId &&
    tokenAddress == WETH[chainId]?.address &&
    (chainId === 1 || chainId === 11155111)
  )
}

export function isTokenWMATIC(tokenAddress?: string, chainId?: ChainId): boolean {
  return false
  // return !!tokenAddress && !!chainId && tokenAddress == WETH[chainId]?.address && chainId === 137
}

export function isTimeout(timeId: NodeJS.Timeout | undefined): timeId is NodeJS.Timeout {
  return typeof timeId !== 'undefined'
}

export const checkIsContract = async (provider: PublicClient, address: string) => {
  try {
    // @ts-ignore
    const code = await provider.getBytecode({ address })
    return code !== undefined
  } catch (error) {
    return false
  }
}

export const formatAmount = (num: ethers.BigNumber) => {
  const normalNum = Number(ethers.utils.formatEther(num))
  const k = 1000
  const mil = k * k
  const bil = mil * k
  const til = bil * k

  if (normalNum < k) return normalNum
  if (normalNum < mil) return `${normalNum / k}k`
  if (normalNum < bil) return `${normalNum / mil}mil`
  if (normalNum < til) return `${normalNum / bil}bil`
  return normalNum
}
